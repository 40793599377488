
import { createRouter, createWebHistory } from 'vue-router'

import home from '@/components/home.vue'
import user from '@/components/user.vue'
import joblist from '@/components/joblist.vue'
import resumeList from '@/components/user/resume/index'
import resumeEdit from '@/components/user/resume/edit'
import resumeInfo from '@/components/user/resume/info'
import resumeAdd from '@/components/user/resume/add'


const routes = [
	{ path: '/', component: home },
	{ path: '/user', component: user},
	{ path: '/joblist', component: joblist},
	{ path: '/user/resume/', component: resumeList },
	{ path: '/user/resume/edit', component: resumeEdit },
	{ path: '/user/resume/info', component: resumeInfo },
	{ path: '/user/resume/add', component: resumeAdd },
	{ path: '/company', component: () => import('@/components/company')},
	{ path: '/company/add', component: () => import('@/components/company/add')},
	{ path: '/company/edit', component: () => import('@/components/company/edit')},
	{ path: '/company/info', component: () => import('@/components/company/info')},
	{ path: '/jobs', component: () => import('@/components/jobs/index')},
	{ path: '/jobs/add', component: () => import('@/components/jobs/add')},
	{ path: '/jobs/edit/:id', component: () => import('@/components/jobs/edit')},
	{ path: '/jobs/info/:id', component: () => import('@/components/jobs/info')},
	{ path: '/user/myApply', component: () => import('@/components/user/myApply')},
	{ path: '/user/myAttention', component: () => import('@/components/user/myAttention')},
	{ path: '/user/income', component: () => import('@/components/user/income')},
	{ path: '/user/withdrawal', component: () => import('@/components/user/income/withdrawal')},
	{ path: '/tool/applys/', component: () => import('@/components/tool/applys/')},
	{ path: '/tool/applys/info', component: () => import('@/components/tool/applys/info')},
	{ path: '/tool/onTheJob', component: () => import('@/components/tool/onTheJob')},
	{ path: '/tool/inFactory', component: () => import('@/components/tool/inFactory')},
	{ path: '/tool/inFactory/info', component: () => import('@/components/tool/inFactory/info')},
	{ path: '/tool/onTheJob/info', component: () => import('@/components/tool/onTheJob/info')},
	{ path: '/jobs/settlement/', component: () => import('@/components/jobs/settlement')},
	{ path: '/jobs/settlement/staff/', component: () => import('@/components/jobs/settlement/staff')},
	{ path: '/jobs/settlement/staff/add', component: () => import('@/components/jobs/settlement/staff/add')},
	{ path: '/jobs/settlement/staff/info', component: () => import('@/components/jobs/settlement/staff/info')},
	{ path: '/jobs/settlement/staff/edit', component: () => import('@/components/jobs/settlement/staff/edit')},
	{ path: '/jobs/signup', component: () => import('@/components/jobs/signup')},
	{ path: '/workList', component: () => import('@/components/worklist')},
	{ path: '/provider', component: () => import('@/components/tool/provider')},
	{ path: '/searchjob', component: () => import('@/components/searchjob')},
	{ path: '/team', component: () => import('@/components/distribution/team')},
	{ path: '/team/team', component: () => import('@/components/distribution/team/team')},
	{ path: '/team/withdrawal', component: () => import('@/components/distribution/team/withdrawal')},
	{ path: '/team/userInfo/:id', component: () => import('@/components/distribution/team/userInfo')},
	{ path: '/terms', component: () => import('@/components/terms')},
	{ path: '/user/joblist', component: () => import('@/components/user/joblist')}
  ]
  
const router = createRouter({
	history: createWebHistory(),
	routes
})

export default router
