<template>
    <div id="head" class="head-box d-flex flex-nowrap radius-bottom">
        <div class="headimg col-3 d-flex justify-content-center align-items-center">
            <img class="rounded-circle" :src="data.avatar" />
        </div>
        <div class="d-flex flex-column justify-content-center" :class="{'col-5': route.path == '/user/income', 'col-6': route.path !== '/user/income'}">
            <p class="title">{{ data.name }}</p>
            <p id="contact" v-if="route.path !== '/user/income'">推荐人：{{ data.contact.name }}{{ data.contact.phone }}</p>
            <p class="level radius-10 text-center lh-sm fs-7">普通会员</p>
        </div>
        <div class="qrc-btn col d-flex justify-content-center" @click="genCode" v-if="route.path !== '/user/income'">
            <a id="qrcode">邀请好友</a>
        </div>
        <div class="qrc-btn col d-flex justify-content-center" v-if="route.path == '/user/income'">
            <a id="qrcode">余额<br /> {{ money }}元</a>
        </div>

    </div>
    <div v-show="showCode" class="wrap" @click="showCode = false">
        <img :src="qcode" />
    </div>
</template>

<script>

import { ref } from 'vue'
import { useRoute } from 'vue-router'
import QRCode from 'qrcode'

export default {
    name:'userheader',
    props: [
        'money'
    ],
    setup () {
        const route = useRoute()
        const data = ref({
            id: 100, //会员Id
            name: '陈小春', //姓名
            phone: '180180180190', //电话
            avatar: 'https://picsum.photos/60/60',
            contact: {id: 100, name: '陈一龙', phone: '180801180180'},
            level: { id: 1, name: 'VIP' }
        })

        const showCode = ref(false)
        const ready = ref(false)
        const qcode = ref('')

        function genCode () {
            if (ready.value) {
                showCode.value = true
                return
            }

            QRCode.toDataURL('https://hr.121cc.cn/user/reg?ids=' + data.value.id + '&phone=' + data.value.phone, (err, url) => {
                if (err) throw err
                
                qcode.value = url
                ready.value = true
                showCode.value = true
            })
        }

        return {
            route,
            data,
            qcode,
            genCode,
            showCode
        }
    }

}

</script>
<style scoped>
* {
    margin:0;
    padding:0;
}
.head-box {
    background:linear-gradient(to top,#00796B,#009688);
    height:100px;
}

.headimg img {
    width: 70px;
    height: 70px;
}

#head .d-flex .title {
    font-weight:600;
    color:#ffffff;
}
#head .level {
    width: 50px;
    border: 0.8px solid rgb(240, 240, 240);
    color: rgb(240, 240, 240);
}
#head .d-flex #contact {
    font-size:0.75em;
    color:#ced4da;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#head #qrcode{
    cursor: pointer;
        margin:40px 10px 0 0;
        border:1px solid #17a2b8 !important;
        background:#00796B !important;
        height:28px;
        border-radius:25px;
        line-height:1.8em;
        font-size:0.9rem;
        color:#ffffff !important;
        width: 80px;
        text-align: center;
    }

.wrap {
    z-index: 99999;
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    text-align: center;
}

.wrap img {
    margin-top: 5em;
    width: 200px;
}

</style>
