

//工作经验
const JOBINDEX = [
    '无',
    '1年以下',
    '1年',
    '2年',
    '3年',
    '4年以上'
]
//学历
const SCHOOLING = [
    '无',
    '小学',
    '初中',
    '高中/中专',
    '大专',
    '本科',
    '专科',
    '博士',
    '硕士'
]
//学历要求
const JOBS_SCHOOLING = [
    '无',
    '小学以上',
    '初中以上',
    '高中/中专以上',
    '大专以上',
    '本科以上',
    '专科以上',
    '博士以上',
    '硕士以上'
]
//工资范围
const SALARYINDEX = [
    '3000-4500元',
    '3500-5000元',
    '4000-5500元',
    '4500-6000元',
    '5000-6800元',
    '5500-7000元',
    '6000-7800元',
    '8000-12000元'
]
//民族
const NATION = {
        "0": "汉族",
        "1": "蒙古族",
        "2": "回族",
        "3": "藏族",
        "4": "维吾尔族",
        "5": "苗族",
        "6": "彝族",
        "7": "壮族",
        "8": "布依族",
        "9": "朝鲜族",
        "10": "满族",
        "11": "侗族",
        "12": "瑶族",
        "13": "白族",
        "14": "土家族",
        "15": "哈尼族",
        "16": "哈萨克族",
        "17": "傣族",
        "18": "黎族",
        "19": "傈僳族",
        "20": "佤族",
        "21": "畲族",
        "22": "高山族",
        "23": "拉祜族",
        "24": "水族",
        "25": "东乡族",
        "26": "纳西族",
        "27": "景颇族",
        "28": "柯尔克孜族",
        "29": "土族",
        "30": "达斡尔族",
        "31": "仫佬族",
        "32": "羌族",
        "33": "布朗族",
        "34": "撒拉族",
        "35": "毛南族",
        "36": "仡佬族",
        "37": "锡伯族",
        "38": "阿昌族",
        "39": "普米族",
        "40": "塔吉克族",
        "41": "怒族",
        "42": "乌孜别克族",
        "43": "俄罗斯族",
        "44": "鄂温克族",
        "45": "德昂族",
        "46": "保安族",
        "47": "裕固族",
        "48": "京族",
        "49": "塔塔尔族",
        "50": "独龙族",
        "51": "鄂伦春族",
        "52": "赫哲族",
        "53": "门巴族",
        "54": "珞巴族",
        "55": "基诺族"
}        

//希望工作地
const EXPECTADDR = [
    '苏州',
    '昆山',
    '上海',
    '常州',
    '无锡',
    '常熟',
    '杭州',
    '深圳',
    '东莞',
    '南通'
]
//企业性质
const NATURE = [
    '私企',
    '个体',
    '上市企业',
    '国企',
    '股份制',
    '集团'
]
//企业类型
const COMPANY_TYPE =[
    '生产|加工|制造',
    '商业服务',
    'it|通达|互联网',
    '金融',
    '房地产|建筑业',
    '贸易|批发|零售|租赁',
    '文化传媒'
]
//福利
const WELFARE = [
    "五险一金",
    "五险",
    "餐补",
    "车补",
    "房补",
    "话补",
    "过节福利",
    "高温补贴",
    "高温假",
    "年终奖金",
    "有薪年假"
]
//工作类型
const JOBS_TYPE =[
    "技工/普工",
    '生产/研发',
    '机械/仪表',
    '电子/电气',
    '质控/安防',
    '制药/生工',
    '服装/食品',
    '化工/能源',
    '汽车',
    '农林牧渔',
    "行政/后勤",
    "账务/会计",
    "家政/保安",
    "餐饮/服务",
    "美容/头发",
    "超市/零售",
    "促销/导购",
    "酒店/旅游",
    '销售',
    '人事管理',
    '人力资源',
    '酒店服务'
]
//希望工作
const EXPECTJOB = [
    '技工/普工',
    '生产/研发',
    '机械/仪表',
    '电子/电气',
    '质控/安防',
    '制药/生工',
    '服装/食品',
    '化工/能源',
    '汽车',
    '农林牧渔',
    '销售',
    '行政/后勤',
    '财务会计',
    '餐饮服务',
    '美容美发',
    '营业员',
    '人事管理',
    '人力资源',
    '酒店服务'
]

//岗位优势
const ADVANTAGE = [
    "包吃住",
    "吃住在厂",
    "提供吃住",
    "不穿无尘服",
    "妹子多",
    "不过安检",
    "可以带手机",
    "长白班",
    "坐班"
]
//年龄要求
const AGEINDEX = [
    '不限',
    "16-35",
    "16-40",
    "16-45",
    "16-48",
    "16-50",
    "16-55",
    "18-35",
    "18-40",
    "18-45",
    "18-48",
    "18-50",
    "18-55"
]

// const SETTLEMENT_TYPE = [
//     {"id":2,"name":"日结","type":"SETTLEMENT_DAILY"},
//     {"id":3,"name":"周结","type":"SETTLEMENT_WEEKS"},
//     {"id":4,"name":"返费","type":"SETTLEMENT_RETURN_FEE"},
//     {"id":1,"name":"小时工","type":"SETTLEMENT_HOURS"},
// ]

//结算方式
const SETTLEMENT_TYPE = [
    "日结",  "周结", "返费", "小时工"
]
//职位类型
const POSITION_TYPE = [
    '正式工',
    '临时工'
]
//工作状态
const WORK_STATUS = {
    "1":"在职",
    "2":"合同期内离职",
    "3":"旷工离职",
    "4":"正常离职",
    "5":"未通过",
    "6":"未报到",
    "7":"面试通过未来"
}
//投递后的状态
const APPLY_STATUS = {
    "0":"待确认",
    "1":"通知面试",
    "2":"未通过",
    "3":"在职",
    "4":"合同期内离职",
    "5":"旷工离职",
    "6":"正常离职",
    "7":"未报到",
    "8":"面试通过未来",
    "9":"电话未接",
    "10":"未联系到人"

}


export {
    APPLY_STATUS,
    WORK_STATUS,
    POSITION_TYPE,
    WELFARE,
    SETTLEMENT_TYPE,
    JOBS_SCHOOLING,
    JOBS_TYPE,
    NATION,
    EXPECTADDR,
    JOBINDEX,
    SCHOOLING,
    EXPECTJOB,
    SALARYINDEX,
    COMPANY_TYPE,
    NATURE,
    ADVANTAGE,
    AGEINDEX
}
