<template>
    <div class="container">
        <div v-for="(resume, index) in resumes" :key="index" class="box row my-2 bg-white rounded">
            <div class="col-3 py-1 pr-0 pic text-center">
                <img :src="resume.avatar" class="rounded-circle" alt="">
            </div>
            <div class="col-6 pl-3 d-flex flex-column justify-content-center title">
                <a :href="'/user/resume/info?id=' + resume.id">
                <h3>{{ resume.name }}</h3>
                <p>期望工作: {{ EXPECTJOB[resume.expectJob]}}</p>
                <p>期望工作地: {{ resume.expectAddr }}</p>
                <p>更新时间: {{ resume.creattime }}</p>
                </a>
            </div>
            <div class="col-3 d-flex flex-column justify-content-center price">
                <div class="text-center"><a :href="'/user/resume/edit?id=' + resume.id" class="href"><i class="fa-regular fa-pen-to-square"></i> 编 辑</a></div>
            </div>
        </div>
        <div class="py-3">
            <button class="btn w-100 btn-info" type="button" v-if="isLook" @click="goPage('/user/resume/add')">添加简历</button>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'
import { EXPECTJOB } from '@/lib/data'
export default {
    name: "resumeList",
    setup(){
        const route = useRoute()
        const router = useRouter()
        let resumes = ref([])
        const isLook = ref(true)
        const num = 3
        function getInfo () {
            axios.get('http://192.168.2.220:5000/resumes').then((response) => {
                resumes.value = response.data.data
                if(resumes.value.length >= num){
                    isLook.value = false
                }
            })
        }

        function goPage(url){
            router.push(url)
        }

        onMounted(() => {
            const id = route.query.id
            getInfo(id)
        })

        return {
            EXPECTJOB,
            isLook,
            goPage,
            resumes
        }
    }

}
</script>

<style scoped>

.box {
    padding: 10px 0;
    margin: 0;
    height: auto;
}

.rounded {
    border-radius: 0.4rem!important;
}

.pic img {
    width: 70px;
    height: 70px;
}

.title h3 {
    font-size: 1rem;
    font-weight: 600;
    margin: 0px;
    text-align: left;
}

.title p {
        color: #999999;
        margin: 0px;
        text-align: left;
        font-size: .7rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
}

.title strong {
    display: block;
    color: #ff5200e0;
    margin: 0px;
        text-align: left;
}

.price div {
    line-height: 2rem;
}


</style>
