<template>
  
    <div class="nav-box d-flex w-100 align-items-center">
        <div class="home col p-0">
            <router-link to="/" class="text-decoration-none">
                <p><i class="fa-solid fa-house"></i></p>
                首页
            </router-link>
        </div>
        <div class="job col p-0">
            <router-link to="/searchjob" class="text-decoration-none" title="工作大厅">
                <p><i class="fa-brands fa-stack-overflow"></i></p>
                工作大厅
            </router-link>
        </div>
        <div class="attendance col p-0" v-if="true">
            <router-link to="/workList" class="text-decoration-none">
                <p><i class="fa-solid fa-user-doctor"></i></p>
                上班打卡 
            </router-link>
            
        </div>
        <div class="user col p-0">
            <router-link to="/user" class="text-decoration-none">
                <p><i class="fa-solid fa-house-user"></i></p>
                个人中心
            </router-link>
        </div>
    </div>

</template>

<script>
export default {
    name:'navbar'
}
</script>

<style scoped>
    p{
        margin: 0;
        padding: 0;
    }
    .nav-box {
        position:fixed;
        bottom: 0;
        margin: 0 auto;
        height: auto;
        background-color: #fff;
        border-top: 1px solid #75757554;
    }

    .nav-box div {
        text-align: center;
    }

    .router-link-active, .router-link-active i, .router-link-active a {
        color: #009688 !important;
    }
</style>