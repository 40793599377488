<template>
    <div class="resume-edit container my-2">
        
        <form>
            <div class="d-flex justify-content-center img">
                  <img :src="avatar">
                <div class="file img" id="res">
                    <input ref="file" type="file" name="resumeavatar" id='upimg' accept="image/*" @change="fileHandler"/>  
                </div>
            </div>
        <div>
            <div class="input-group border-0 mb-3">
                <span class="input-group-text" >名 称<i class="rqd">*</i></span>
                <input type="text" class="form-control" name="name" placeholder="请输入简历名称" required v-model.trim.lazy="uresume.title" />
            </div>
            <div class="input-group border-0 mb-3">
                <span class="input-group-text" >姓 名<i class="rqd">*</i></span>
                <input type="text" class="form-control" name="name" placeholder="请输入姓名" required v-model.trim.lazy="uresume.name" />
            </div>
            <div class="input-group mb-3">
                <span class="input-group-text" >电 话<i class="rqd">*</i></span>
                <input type="number" class="form-control" placeholder="请输入手机号码" required v-model.number.lazy="uresume.phone" />
            </div>
            <div class="input-group mb-3">
                <span class="input-group-text" >身份证号<i class="rqd">*</i></span>
                <input type="number" class="form-control" placeholder="请输身份证号码" required v-model.number.lazy="uresume.id_num">
            </div>
            <div class="input-group mb-3">
                <label class="input-group-text" for="inputGroupSelect01">性 别</label>
                <select class="form-select" v-model="uresume.sex">
                    <option selected value="0">女</option>
                    <option value="1">男</option>
                </select>
            </div>
            <div class="input-group mb-3">
                <span class="input-group-text">年 龄</span>
                <input id="age" type="date" class="form-control" v-model="uresume.age" />
            </div>
            <div class="input-group mb-3">
                <span class="input-group-text" >民 族</span>
                <select class="form-select" v-model="uresume.nation">
                    <option v-for="(site,index) in NATION" :key="index" :value="index">{{ site }}</option>
                </select>
            </div>
            <div class="input-group mb-3">
                <span class="input-group-text" >学 历</span>
                <select class="form-select" v-model="uresume.schooling">
                    <option v-for="(site,index) in SCHOOLING" :key="index" :value="index">{{ site }}</option>
                </select>
            </div>
            <div class="input-group mb-3">
                <span class="input-group-text" >经 验</span>
                <select class="form-select" v-model="uresume.job_index">
                    <option v-for="(site,index) in JOBINDEX" :key="index" :value="index">{{ site }}</option>
                </select>
            </div>
            <div class="input-group mb-3">
                <span class="input-group-text" >籍贯地址</span>
                <input type="text" class="form-control" placeholder="请输入籍贯地址" v-model.number.lazy="uresume.address" >
            </div>
            <div class="input-group mb-3">
                <span class="input-group-text" >期望薪资</span>
                <select class="form-select" v-model="uresume.salary_index">
                    <option v-for="(site,index) in SALARYINDEX" :key="index" :value="index">{{ site }}</option>
                </select>
            </div>
            <div class="input-group mb-3">
                <span class="input-group-text" >期望工作类型</span>
                <select class="form-select" v-model="uresume.expect_job">
                    <option v-for="(site,index) in EXPECTJOB" :key="index" :value="index">{{ site }}</option>
                </select>
            </div>
            <div class="input-group mb-3">
                <span class="input-group-text" >期望工作地</span>
                <input type="text" class="form-control" placeholder="请输入期望地址" v-model.number.lazy="uresume.expect_addr">
            </div>
            <div class="mb-3">
                <span class="input-group-text" >个人简介</span>
                <textarea name="des" class="form-control" placeholder="请输入期望地址" v-model.lazy="uresume.description"></textarea>
            </div>
            <div class="d-grid gap-2 col-6 mx-auto">
                <button v-if="btnStatus" class="btn btn-primary btn-lg" type="button" @click.prevent="submit()">提 交</button>
                <p v-if="!btnStatus">{{ errormsg }}</p>
            </div>
            <input type="text" style="display:none" :value="uresume.id">
        </div>
        </form>   
    </div>
    <hint v-if="isOk" :msg="msg" @btnok="isOk=false" />
    <Cropping v-if="showEditimg" :picture="image" @close="close" @finish="save"></Cropping>
</template>

<script>


import { watch, ref, onMounted } from 'vue'
import axios from 'axios'
import { useRoute } from 'vue-router'
import { NATION, EXPECTJOB, JOBINDEX, SCHOOLING, SALARYINDEX  } from '@/lib/data'
import hint from '@/components/hint'
import { checkId } from '@/lib/unitls'
import Cropping from '@/components/lib/Cropping.vue'

export default {
    name:'resumeEdit',
    components:{
        hint,
        Cropping
    },
    
    setup () {     
        const router = useRoute()
        const id = router.query.id
        const isOk = ref(false)
        const btnStatus = ref(true)
        let msg = { type: 'success', message: '提交成功', url:''}
        const errormsg = ref('请输入完整的信息')
        const showEditimg = ref(false)
        const image = ref()
        let file = ref()
        const avatar = ref(null)

        const uresume = ref({
            id: '',
            avatar: '',
            title: '',
            name: null,
            phone: '',
            sex: 1,
            age: '2020-01-01',
            nation: 0,
            address: '',
            id_num: null,
            schooling: 2,
            salary_index: 1,
            job_index: 0,
            expect_job: 0,
            expect_addr: '',
            description: ''
        })

        function fileHandler () {

            image.value = file.value.files[0]
            showEditimg.value = true
        }

        function save (data) {
            showEditimg.value = false
            console.log('aaa', data)
            const f = new File([URL.createObjectURL(data)], image.value.name, {type: image.value.type})
console.log('fff', f)
            const form = new FormData()
            form.append('file', f)
            axios.post('/api/v1/upload/image', form).then((response) => {
                if(response.data.code == 0) {
                    uresume.value.avatar = response.data.data.id
                    avatar.value = response.data.data.path
                }

                console.log(response)
            })    
        }

        function close() {
            showEditimg.value = false
        }

        function getInfo(id) {
            axios.get('/api/v1/resume/?id=' + id).then((response) => {
                uresume.value = response.data.data
            })
        }

        function submit() {
            if ( 8 < uresume.value.name.length || uresume.value.name.length < 2 ) {
                btnStatus.value = false
                errormsg.value = '姓名名不能小于2个字或大于8个字'
                return
            }
            if ( 11 < String(uresume.value.tel).length || String(uresume.value.tel).length < 11 ) {
                btnStatus.value = false
                errormsg.value = '手机号码不正确请重新输入'
                return
            }
            if ( !uresume.value.idNum ) {
                btnStatus.value = false
                errormsg.value = '身份证号码不能为空'
                return
            }
            axios.put('/test.json?id=100', uresume.value).then((response) => {
                if (response.data.code == 200){
                    msg.type = 'success'
                    msg.message = '提交成功'
                    msg.url = '/resume/'
                    isOk.value = true
                    return
                } else {
                    msg.type = 'danger'
                    msg.message = response.data.message
                    isOk.value = true
                    return
                }
            })
        }

        onMounted(() => {
            getInfo(id)
        })

        watch(() => uresume.value.name, (newValue) => {
            if (newValue.length < 2 || newValue.length > 8) {
                errormsg.value = '姓名名不能小于2个字或大于8个字'
                alert(errormsg.value)
                btnStatus.value = false
                return
            }
            btnStatus.value = true
            return
        })

        watch(() => uresume.value.tel, (a) => {
            if(String(a).length !== 11) {
                errormsg.value = '手机号码不正确请重新输入'
                alert(errormsg.value)
                btnStatus.value = false
                return
            }
            btnStatus.value = true
            return
        })

        watch(() => uresume.value.idNum, (newValue) => {
            if (checkId(newValue)===false) {
                errormsg.value = '身份证号码错误，请重新输入'
                alert(errormsg.value)
                btnStatus.value = false
                return
            }
            btnStatus.value = true
            return
        })

        watch(() => uresume.value.des, (newValue) => {
            if (newValue.length < 6 || newValue.length > 200) {
                errormsg.value = '个人简介不能少于6个或多于200个字符'
                alert(errormsg.value)
                btnStatus.value = false
                return
            }
            btnStatus.value = true
            return
        })

        return {
            file,
            uresume,
            showEditimg,
            fileHandler,
            image,
            save,
            close,
            JOBINDEX,
            SCHOOLING,
            SALARYINDEX,
            EXPECTJOB,
            NATION,
            btnStatus,
            msg,
            errormsg,
            submit,
            isOk,
            avatar
        }
    }
}
</script>

<style scoped>
.resume-edit .input-group-text {
    border-radius: 0.25rem 0 0 0.25rem;
}

.img {
    text-align: center;
    padding: 5px;
    margin-bottom:5px;
}
.img img {
    width: 60px;
    height: 70px;
}
.img input {
    position: absolute;
    font-size: 5rem;
    line-height: 100px;

}
.file {
    position: absolute;
    /* display: inline-block; */
    top:10px;
    width:60px;
    height: 70px;
    border-radius: 4px;
    padding: 4px 12px;
    overflow: hidden;
    text-decoration: none;
    text-indent: 0;
    line-height: 20px;
}
.file input {
    position: absolute;
    font-size: 100px;
    right: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
}
/* .file:hover {
    background: #AADFFD;
    border-color: #78C3F3;
    color: #004974;
    text-decoration: none;
} */
.rqd {
    color:red;
}
form select {
    flex: auto;
    border-radius: 0;
    background: #fff;
    background-color: #ffffff;
    border-color: #ffffff;
}

form textarea {
    width: 100%;
    min-height: 80px;
    background-color: #ffffff;
    border-color: #ffffff;
}

form .input-group-text, form .form-control {
    background-color: #ffffff;
    border-color: #ffffff;
}

</style>
