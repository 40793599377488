<template>
<div class="modal d-block" tabindex="-1">
  <div class="modal-dialog border-0">
    <div class="modal-content border-0">
      <div class="modal-header border-0 p-1">
        <h5 class="modal-title"></h5>
      </div>
      <div class="modal-body py-2">
        <div class="w-60"><img id="image" class="w-75" :src="image" /></div>
      </div>
      <div class="modal-footer">
        <button type="button" @click="submit" class="btn btn-success mx-3 w-45 col-3">保存</button>
        <button type="button" @click="close"  class="btn btn-secondary w-45 col-3">取消</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import Cropper from 'cropperjs'
import { defineComponent, ref, onMounted, nextTick } from 'vue'

export default defineComponent({
  name: 'Cropping',
  props: {
    picture: File
  },
  setup (props, { emit }) {
    var cp = null
    const image = ref(
      URL.createObjectURL(props.picture)
    )

    onMounted(() => {
      nextTick(() => {
        const el = document.querySelector('#image')
        cp = new Cropper(el, {
          dragMode: 'crop',
          aspectRatio: 24 / 25,
          autoCropArea: 0.65,
          restore: false,
          guides: false,
          center: false,
          highlight: true,
          cropBoxMovable: true,
          cropBoxResizable: true,
          toggleDragModeOnDblclick: false
        })
      })
    })

    function submit () {
      cp.getCroppedCanvas({
        imageSmoothingQuality: 'high'
      }).toBlob((blob) => {
        emit('finish', blob)
      })
    }

    function close () {
      emit('close')
    }

    return {
      close,
      image,
      submit
    }
  }
})
</script>
<style scoped>
.modal {
  background-color: rgba(0, 0, 0, .3);
}
</style>