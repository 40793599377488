<template>
    <div class="box">
        <div class="box-top align-middle">
            <span class="align-middle">{{user.title ? user.title : user.name }}</span>&nbsp;&nbsp;<span class="align-middle my-2">简历id:{{ user.id }}</span>
        </div>
        <div class="box-headimg fa-solid fa-user clearfix">
            <img v-show="user.avatar" :src="user.avatar" alt="">
        </div>
        <div class="box-countent clearfix">
                <h3>姓名:{{ user.name }}</h3>
                <p>{{ user.des }}</p>
                <p>{{ user.tel }}</p>
                <p>年龄: {{ user.age }} 岁</p>
                <p>性别: {{ user.sex == 0 ? '女' : '男' }}</p>
                <p>民族: {{ NATION[user.nation] }}</p>
                <p>学历: {{ SCHOOLING[user.schooling] }}</p>
                <p>工作经验: {{ JOBINDEX[user.job_index] }}</p>
                <p>期望薪资: {{ SALARYINDEX[user.salary_index] }}</p>
                <p>期望工作: {{ EXPECTJOB[user.expect_job] }}</p>
                <p>工作区域: {{ user.expect_addr }}</p>
                <p>更新时间: {{ user.updat_time ? user.updat_time : user.create_time }}</p>
                <p>查看次数: {{ user.looks }}</p>
                <p>审核状态: {{ user.status === 0 ? '未审核' : '已审' }}</p>
        </div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <a :href="'/user/resume/edit?id=' + user.id">
                    <i class="fa-regular fa-pen-to-square"></i><br />
                    编辑简历
                    </a>
                </div>
                <!-- <div class="col">
                    <a href="/user/resumeDetails?id=100">
                    <i class="fa-solid fa-bars-staggered"></i><br />
                    查看详情
                    </a>
                </div> -->
                <div class="col">
                    <a>
                    <i class="fa-brands fa-fantasy-flight-games"></i><br />
                    智能匹配
                    </a>
                </div>
                <!-- <div class="col">
                    <i class="fa-solid fa-right-left"></i><br />
                    设置隐私
                </div> -->
                <div class="col" @click="getInfo()">
                    <a>
                    <i class="fa-regular fa-trash-can"></i><br />
                    删除简历
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="msg" v-if="msginfo">
        <hint></hint>
    </div>
</template>

<script>

import { useRoute } from 'vue-router'
import { NATION, EXPECTJOB, JOBINDEX, SCHOOLING, SALARYINDEX  } from '@/lib/data'
import { provide, ref, reactive, onMounted, onBeforeMount } from 'vue'
import axios from 'axios'
import hint from '@/components/hint'
import { getYear } from '@/lib/unitls'


export default {
    name:'resume',
    components:{
        hint
    },
    setup () {
        const router = useRoute()
        const year = getYear()
        const msg = reactive(['success','提交成功']) 
        provide(['msg', msg],['user',user])
        let msginfo = false
        let isResume = false


        let user = ref({
            id:'1000',
            uid: '',
            name: '',
            des: '',
            tel: '',
            sex: '',
            age: '123456',
            idNum: '',
            nation: '',
            schooling: '',
            salaryindex: '',
            jobindex: '',
            address:'',
            expectJob:'',
            expectAddr: '',
            creattime: '',
            looks: '',
            status: '',
            headimg: ''
        })

        function getInfo(id) {
            axios.get('/api/v1/resume/', { params: { id }}).then((response) => {
                user.value = response.data.data
                const d = new Date(user.value.age)
                user.value.age = parseInt(year) - d.getFullYear()
                console.log(user)
            })
        }

        onBeforeMount(()=>{
            
        })
        

        onMounted(() => {
            const id = router.query.id
            user.value.id = id
            getInfo(id)
        })
        
        
        return {
            year,
            user,
            isResume,
            msginfo,
            NATION, 
            EXPECTJOB, 
            JOBINDEX, 
            SCHOOLING, 
            SALARYINDEX
        }
        
        
    }
    

}
</script>

<style scoped>
.box{
    background:#fff;
    box-shadow: 0px 7px 8px 3px #9c9b9b;
    border-radius:8px;
    margin: 20px;
    padding-bottom:20px;
    height: auto;
    font-size: 0.9rem;
}
.box-top{
    width: 100%;
    height: 40px;
    background: #00796B;
    border-radius:8px 8px 0 0;
    color:#fff;
    padding: 5px 10px;
}
.box-top span:first-child {
    font-size: 1.2rem;
    color: #fff;
}
.box-top span:last-child {
    font-size: 0.8rem;
    line-height: 1.5rem;
    color: #fff;

}
h3 {
    margin: 20px 0;
}
.box-headimg {
    position:absolute;
    right:40px;
    top:80px;
    border-radius:50px;
    height: 60px;
    width: 60px;
    background:#d2d4d4;
    font-size: 2.5rem;
    text-align: center;
    line-height: 3.5rem;
    color: #fff;
}
.box-headimg img {
    position: absolute;
    top:-5px;
    left: -5px;
    width:70px;
    height:70px;
    border-radius: 50px;
    background-repeat:no-repeat;
    background-size:100% 100%;
    -moz-background-size:100% 100%;
}
.box-countent {
    margin:0 auto;
    padding: 20px;
    width:100%;
    height: auto;
}
.box-countent p {
    line-height:1.5rem;
    margin-bottom: 5px;
}
.col {
    padding: 0;
    text-align: center;
}
.col i {
    font-size: 1.3rem;
    line-height:2rem;
}
.msg {
    position:fixed;
    z-index: 99999;
    top:0;
}
</style>