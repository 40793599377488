<template>
    <div class="w-100">
        <div class="input-group">
            <select class="form-control" v-model="form.province" @change="provinceHandler">
                <option value="null">请选择省</option>
                <option v-for="(name, code) in province" :key="code" :value="code">{{ name }}</option>
            </select>
            <select class="form-control" v-model="form.city" @change="cityHandler">
                <option value="null">请选择市</option>
                <option v-for="(name, code) in city" :key="code" :value="code">{{ name }}</option>
            </select>
            <select class="form-control" v-model="form.area">
                <option value="null">请选择区.</option>
                <option v-for="(name, code) in area" :key="code" :value="code">{{ name }}</option>
            </select>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, watch, reactive, toRaw, nextTick } from 'vue'
import  axios from 'axios'
export default {
    name:"myAddress",
    props: [
        'addr'
    ],
    setup (props, { emit }) {
        let data = null

        let form = reactive({
            province: null,
            city: null,
            area: null
        })
    
        const province = ref([])
        const city = ref([])
        const area = ref([])
         
        watch(form, () => {
            emit('changed', toRaw(form))
        })

        onMounted(()=> {
           
            // 1. 获取数据库数据
            axios.get('/js/area.json').then(async (response) => {
                data = response.data
                
                if (data) {
                    // 2. 拿到默认值
                    // 3. 赋值默认列表
                    province.value = data.cn
            
                    if(props.addr !== undefined){
                        city.value = data[props.addr.province]
                        area.value = data[props.addr.city]
                        
                        await nextTick()

                        // 4. 设置默认值
                        form.province = props.addr.province
                        form.city = props.addr.city
                        form.area = props.addr.area
                    }

                    
                }
            })
        })
        
        function provinceHandler () {
            form.city = null
            form.area = null
            area.value = []
            city.value = data[form.province]
        }

        function cityHandler () {
            form.area = null
            area.value = data[form.city]
        }

        return {
            form,
            province,
            city,
            area,
            provinceHandler,
            cityHandler
        }
    }
}
</script>

<style>

</style>