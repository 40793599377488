<template>
    <Userheader></Userheader>
    <!-- 企业服务 -->
    <div class="container">
        <div class="qiye">
            <div class="qiye-top">
                <i class="fa-solid fa-house-user"></i> 企业服务
            </div>
            <div class="qiye-bottom clearfix">
                <div class="e-list">
                    <router-link to="/jobs/">
                    <p class="qiye-p"><i class="fa-solid fa-briefcase"></i> </p>
                    <p>职位管理</p>
                    </router-link>
                </div>
                <div class="e-list">
                    <router-link to="/company/">
                    <p class="qiye-p"><i class="fa-solid fa-house-laptop"></i>   </p>
                    <p>企业管理</p>
                    </router-link>
                </div>
                <div class="e-list">
                    <router-link to="/jobs/settlement">
                    <p class="qiye-p"><i class="fa-solid fa-coins"></i> </p>
                    <p>费用管理</p>
                    </router-link>
                </div>
                <div class="e-list">
                    <router-link class="showinfo" date-ids="" to="/jobs/add" title="发布职位">
                    <p class="qiye-p"><i class="fa-regular fa-paper-plane"></i> </p>
                    <p>发布职位</p>
                    </router-link>
                </div>
            </div>    
        </div>
    </div>
    <!-- 个人服务 -->
    <div class="container">
        <div class="qiye">
            <div class="qiye-top">
                <i class="fa-solid fa-user-tie"> </i>个人服务
            </div>
            <div class="qiye-bottom clearfix">
                <div class="e-list">
                    <router-link to="/user/myApply">
                    <p class="qiye-p"><i class="fa-regular fa-rectangle-list"></i> </p>
                    <p>我的申请</p>
                    </router-link>
                </div>
                <div class="e-list">
                    <router-link to="/user/resume/">
                    <p class="qiye-p"><i class="fa-solid fa-chalkboard-user"></i></p>
                    <p>简历管理</p>
                    </router-link>
                </div>
                <div class="e-list">
                    <router-link to="/team">
                    <p class="qiye-p"><i class="fa-solid fa-users"></i> </p>
                    <p>我的团队</p>
                    </router-link>
                </div>
                <div class="e-list">
                    <router-link to="/user/income" >
                    <p class="qiye-p"><i class="fa-solid fa-hand-holding-dollar"></i> </p>
                    <p>我的收入</p>
                    </router-link>
                </div>
                <div class="e-list">
                    <router-link to="/user/joblist" >
                    <p class="qiye-p"><i class="fa-solid fa-list"></i> </p>
                    <p>工资单</p>
                    </router-link>
                </div>
                <div class="e-list">
                    <router-link to="/user/myAttention" >
                    <p class="qiye-p"><i class="fa-regular fa-star"></i> </p>
                    <p>我的关注</p>
                    </router-link>
                </div>
            </div>    
        </div>
    </div>
    <!-- 工具报表 -->
    <div class="container"  v-if="display">
        <div class="qiye">
            <div class="qiye-top">
                <i class="fa-solid fa-screwdriver-wrench">&nbsp;</i> 工具报表
            </div>
            <div class="qiye-bottom clearfix">
                <div class="e-list">
                    <router-link to="/tool/applys">
                    <p class="qiye-p"><i class="fa-solid fa-list-check"></i> </p>
                    <p>报名管理</p>
                    </router-link>
                </div>
                <div class="e-list" v-if="display">
                    <router-link to="/">
                    <p class="qiye-p"><i class="fa-solid fa-user-clock"></i></p>
                    <p>考勤记录</p>
                    </router-link>
                </div>
                <div class="e-list" v-if="display">
                    <router-link to="/provider">
                    <p class="qiye-p"><i class="fa-regular fa-handshake"></i> </p>
                    <p>供应商管理</p>
                    </router-link>
                </div>
                <div class="e-list" v-if="display">
                    <router-link to="/tool/inFactory">
                        <p class="qiye-p"><i class="fa-solid fa-user-pen"></i> </p>
                        <p>驻厂管理</p>
                    </router-link>
                </div>
                <div class="e-list" v-if="display">
                    <router-link to="/">
                    <p class="qiye-p"><i class="fa-solid fa-users-gear"></i> </p>
                    <p>在离职管理</p>
                    </router-link>
                </div>
            </div>    
        </div>
    </div>
    <!-- 驻厂工具 -->
    <div class="container">
        <div class="qiye">
            <div class="qiye-top">
                <i class="fa-solid fa-screwdriver-wrench">&nbsp;</i>驻厂工具
            </div>
            <div class="qiye-bottom clearfix">
                <div class="e-list">
                    <router-link to="/tool/applys">
                    <p class="qiye-p"><i class="fa-solid fa-list-check"></i> </p>
                    <p>报名管理</p>
                    </router-link>
                </div>
                <div class="e-list" v-if="display">
                    <router-link to="/">
                    <p class="qiye-p"><i class="fa-solid fa-user-clock"></i></p>
                    <p>考勤管理</p>
                    </router-link>
                </div>
                <div class="e-list">
                    <router-link to="/tool/onTheJob">
                    <p class="qiye-p"><i class="fa-solid fa-users-gear"></i> </p>
                    <p>在离职管理</p>
                    </router-link>
                </div>
            </div>    
        </div>
    </div>
    <!-- 供应商工具 -->
    <div class="container" v-if="display">
        <div class="qiye">
            <div class="qiye-top">
                <i class="fa-solid fa-screwdriver-wrench">&nbsp;</i>供应商工具
            </div>
            <div class="qiye-bottom clearfix">
                <div class="e-list">
                    <router-link to="/tool/applys">
                    <p class="qiye-p"><i class="fa-solid fa-list-check"></i> </p>
                    <p>报名管理</p>
                    </router-link>
                </div>
                <div class="e-list">
                    <router-link to="/">
                    <p class="qiye-p"><i class="fa-solid fa-user-clock"></i></p>
                    <p>考勤记录</p>
                    </router-link>
                </div>
                <div class="e-list">
                    <router-link to="/">
                    <p class="qiye-p"><i class="fa-brands fa-accessible-icon"></i> </p>
                    <p>人员动态</p>
                    </router-link>
                </div>
            </div>    
        </div>
    </div>
    <!-- 增值服务 -->
    <div class="container">
        <div class="qiye">
            <div class="qiye-top">
                <i class="fa-solid fa-basket-shopping">&nbsp;</i>日常生活
            </div>
            <div class="qiye-bottom clearfix">
                <div class="e-list">
                    <a href="https://121.121cc.cn/app/index.php?i=2&c=entry&m=ewei_shopv2&do=mobile">
                    <p class="qiye-p"><i class="fa-solid fa-shop"></i> </p>
                    <p>商城</p>
                    </a>
                </div>
                <div class="e-list">
                    <router-link to="/">
                    <p class="qiye-p"><i class="fa-solid fa-chalkboard-user"></i></p>
                    <p>流量卡</p>
                    </router-link>
                </div>
                <div class="e-list">
                    <router-link to="/">
                    <p class="qiye-p"><i class="fa-solid fa-users"></i> </p>
                    <p>文章小说</p>
                    </router-link>
                </div>
            </div>    
        </div>
    </div>
    <!-- about -->
    <div class="container">
        <div class="qiye">
            <div class="qiye-top">
                <i class="fa-brands fa-atlassian">&nbsp;</i>关于我们
            </div>
            <div class="qiye-bottom clearfix">
                <div class="e-list">
                    <router-link to="/">
                    <p class="qiye-p"><i class="fa-regular fa-comment-dots"></i> </p>
                    <p>通知</p>
                    </router-link>
                </div>
                <div class="e-list">
                    <router-link to="/terms">
                    <p class="qiye-p"><i class="fa-brands fa-facebook-f"></i></p>
                    <p>免责声名</p>
                    </router-link>
                </div>
                <div class="e-list">
                    <router-link to="/">
                    <p class="qiye-p"><i class="fa-solid fa-book-open"></i> </p>
                    <p>关于我们</p>
                    </router-link>
                </div>
                <div class="e-list">
                    <a class="showinfo" date-ids="" href="javascript:;" title="发布职位" @click="show()">
                    <p class="qiye-p"><i class="fa-solid fa-user-astronaut"></i> </p>
                    <p>客服</p>
                    </a>
                </div>
            </div>    
        </div>
    </div>
    <div id="myanimate" v-if="showModal">

     </div>
</template>

<script setup>

import { ref, onMounted } from 'vue'
import Userheader from '@/components/user/Header.vue'
const num_company = 1001
const num_user = 1002
const num_tool_form = 1003
const num_in_factory = 1004
const num_provider = 1005
const num = ref([
    {id: 1000, name: "main", status: false},
    {id: 1001, name: "company", status: false},
    {id: 1002, name: "user", status: false},
    {id: 1003, name: "tool_form", status: false},
    {id: 1004, name: "in_factory", status: false},
    {id: 1005, name: "provider", status: false}
])
const display = ref(false)
let showModal = ref(false)
let title = ref()
let factory = ref(false)
// let c = ref(false)
function showContent() {
    title.value = "fasdfas"
    showModal.value = true
}
function show (){
    showModal.value = true
    myAnimate()
}
function myAnimate (){
    let el = document.getElementById('myanimate')
    // alert(1)
    el.animate({
        "left":"0"
    })
}

onMounted(() => {
    window.scrollTo(0, 0)
    document.title = "用户中心@97打工网"
})

</script>

<style scoped>
.fa-solid, .fa-brands {
    color: #009688;
}

.qiye{
    clear:both;
    position:relative;
    background:#fff;
    margin-top:10px;
    border-radius:10px;
    left:0px;
    right:0px;
    height:auto;
}
.qiye-top{
    padding: 10px;
    border-bottom:1px solid #d3d3d3;
}
.qiye-bottom{
    padding: 14px 10px 0 10px;
}

.qiye-bottom a p:last-child {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.562) !important;
}
.qiye-table{
    width:100%;
    height: 60px;
    text-align: center;
    font-size:1.3em;
}
.qiye-p{
    line-height: 2em;
    margin-bottom:0;
    font-size:1rem;
    
}
.qiye-p i {
    font-size:1.2em;
    /* color:#212121; */
    color: #009688;
}
.user{
    position: relative;
    top:20px;
    background:#fff;
    border-radius:10px;
    left:0px;
    right:0px;
    height: auto;
}
.soft{
    position:relative;
    margin: 0;
    background:#fff;
    top:25px;
    border-radius:10px;
    left:0px;
    right:0px;
    height: auto;
}
.about{
    position:relative;
    margin: 0;
    background:#fff;
    top:30px;
    border-radius:10px;
    left:0px;
    right:0px;
    height: auto;
}
.e-list{
    text-align: center;
    font-size:1.3em;
    float:left;
    width:25%;
    padding-bottom: .75em;
}
.e-list p {
    font-size:1rem;
    color:#212121;
    margin-bottom:0;
}
</style>