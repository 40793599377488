import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import { createPinia } from 'pinia'
import vRegion from 'v-region'
import jmodal from '@/components/jmodal.vue' //弹窗
import search from "@/components/search" //搜索
import myAddress from '@/components/lib/address'  //地址三联动
import Hint from '@/components/hint' //提示框


import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

const ap = createApp(App)
const pinia = createPinia()

ap.component('jmodal', jmodal)
ap.component('search', search)
ap.component('myAddress', myAddress)
ap.component('Hint', Hint)


ap.use(pinia)
ap.use(router)
ap.use(vRegion)
ap.mount('#app')
