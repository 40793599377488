
import axios from 'axios'
// import { useRouter } from 'vue-router'

// const router = useRouter()

//获取当前年份
function getYear () {
    const date = new Date();
    return date.getFullYear()
}

//校验身份证号码
function checkId (id) {
    return /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/.test(id)
}

function getUrlCode(name) {  
    var match = RegExp('[?&]' + name + '=([^&]*)')  
                    .exec(window.location.search);  
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));  
}

//处理请求返回的信息
// function getUrl(url,data,isOk){
//     document.createElement("script").setAttribute("src", "/js/axios.min.js")
//     const axios = require('axios')
//     const msg = { type: 'success', message: '提交成功', url:'' }
//     axios.get(url, data).then((response) => {
//         if (response.data.code == 200){
//             msg.type = 'success'
//             msg.message = '提交成功'
//             msg.url = '/resume/'
//             isOk.value = true
//             return msg
//         } else {
//             msg.type = 'danger'
//             msg.message = response.data.message
//             isOk.value = true
//             return msg
//         }
//     })
// }


//保荐到本地localstorage; data 为对像
function saveLocalStor (data){
    for ( let key in data){
        localStorage.setItem(key,data[key])
    }
}
//读取本地localstorage
function readLocalStor(str){
    return localStorage.getItem(str)
}
//清除本地localstorage;data 为数组
function deleteData(data){
    for ( let i = 0; i < data.length; i++){
        localStorage.removeItem(data[i])
    }   
}
//清除本地所有localstorage
function deleteAllData(){
    localStorage.clear()
}

function getImageFileFromUrl(url, imageName) {
    return new Promise((resolve, reject) => {
        var blob = null;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.setRequestHeader('Accept', 'image/jpeg');
        xhr.responseType = "blob";
        xhr.onload = () => {
            blob = xhr.response;
            let imgFile = new File([blob], imageName, { type: 'image/jpeg' });
            resolve(imgFile);
        };
        xhr.onerror = (e) => {
            reject(e)
        };
        xhr.send();
    });
}

let AREAS = null

/* 获取行政区数据 */
function getArea () {
    return new Promise(resolve => {
        if (AREAS) {
            resolve(AREAS)
        } else {
            axios.get('/js/area.json').then(data => {
                AREAS = data
                resolve(AREAS)
            })
        }
    })
}

//微信登录APi

function isLogin() {
    if (!getCookie('token')) {
        const returnUrl = encodeURIComponent("https://hr.121cc.cn/api/v1/login/wechat?to=" + window.location.pathname)
        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxea6521358c935ee4&redirect_uri="+ returnUrl +"&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect"
    }
}

//设置cookie
function setCookie(c_name, value, expiredays) {
    var exdate = new Date()
    exdate.setTime(exdate.getTime() + expiredays*1)
    document.cookie = c_name + "=" + value +
      ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString())
  }
//读取cookie
function getCookie(c_name) {
    if (document.cookie.length > 0) {
        var c_start = document.cookie.indexOf(c_name + "=")
        if (c_start != -1) {
        c_start = c_start + c_name.length + 1
        var c_end = document.cookie.indexOf(";", c_start)
        if (c_end == -1) c_end = document.cookie.length
        return unescape(document.cookie.substring(c_start, c_end))
        }
    }
return ""
}
//删除cookie
function delCookie(uname) {
    setCookie(uname, "",  -1);
}

 //时间代码
//  setInterval("fun(show_time)",1);  //调用
 function fun(timeID){ 
     var date = new Date();  //创建对象  
     var y = date.getFullYear();     //获取年份  
     var m =date.getMonth()+1;   //获取月份  返回0-11  
     var d = date.getDate(); // 获取日  
    //  var w = date.getDay();   //获取星期几  返回0-6   (0=星期天) 
     var ww = ' 星期'+'日一二三四五六'.charAt(new Date().getDay()) ;//星期几
     var h = date.getHours();  //时
     var minute = date.getMinutes()  //分
     var s = date.getSeconds(); //秒
     var sss = date.getMilliseconds() ; //毫秒
     if(m<10){
         m = "0"+m;
     }
     if(d<10){
         d = "0"+d;
     }
     if(h<10){
         h = "0"+h;
     }
     
     
     if(minute<10){
         minute = "0"+minute;
     }
     
     
     if(s<10){
         s = "0"+s;
     }
     
     if(sss<10){
         sss = "00"+sss;
         }else if(sss<100){
         sss = "0"+sss;
     }
     document.getElementById(timeID).innerHTML = y+"-"+m+"-"+d+" &nbsp; "+h+":"+minute+":"+s+" &nbsp; "+" "+ww;
}
//权限
function authority(obj, rolId) {
    
}
//清除弹窗Url
function clearUrl() {
    window.alert = function(name){
        var iframe = document.createElement("IFRAME");
        iframe.style.display="none";
        document.documentElement.appendChild(iframe);
        window.frames[0].window.alert(name);
        iframe.parentNode.removeChild(iframe);
    }
    window.confirm = function (message) {
        var iframe = document.createElement("IFRAME");
        iframe.style.display = "none";
        iframe.setAttribute("src", 'data:text/plain,');
        document.documentElement.appendChild(iframe);
        var alertFrame = window.frames[0];
        var result = alertFrame.window.confirm(message);
        iframe.parentNode.removeChild(iframe);
        return result;
    }
}


export {
    authority,
    clearUrl,
    fun,
    isLogin,
    delCookie,
    setCookie,
    getCookie,
    getYear,
    checkId,
    saveLocalStor,
    readLocalStor,
    deleteData,
    deleteAllData,
    getUrlCode,
    getImageFileFromUrl,
    getArea
}

