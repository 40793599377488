<template>
<span class="glyphicon glyphicon-search" aria-hidden="true"></span>
    <!-- <div>
        <search/>
    </div> -->
    
    <div class="container px-0">
        <div class="box row my-2 bg-white" v-for="(job, index) in data" :key="index">
            <div class="col-3 py-1 pr-0 pic text-center">
                <img :src="job.logo">
            </div>
            <div class="col-6 d-flex flex-column justify-content-center px-1 title">
                <router-link class="text-decoration-none" :to="'/jobs/info/' + job.id">
                    <h3>{{ job.name }}</h3>
                    <p class="py-1"><span v-for="(value, index) in job.tags" :key="index">{{ ADVANTAGE[value] }}&nbsp; </span></p>
                    <strong>{{ SALARYINDEX[job.salarys] }}</strong>
                </router-link>
            </div>
            <div class="col-3 d-flex flex-column justify-content-center price px-1 pe-2">
                <div class="text-center">{{ SETTLEMENT_TYPE[job.settlement] }}</div>
                <div class="text-center fs-8 py-2">{{ job.price }}元/小时</div>
            </div>
        </div>
    </div>
</template>

<script>
import { SALARYINDEX, SETTLEMENT_TYPE, ADVANTAGE } from '@/lib/data'
export default {
    name:"jobs",
    props: [
        'data'
    ],
    setup() {   
        return {
            SALARYINDEX,
            SETTLEMENT_TYPE,
            ADVANTAGE
        }
    }

}
</script>

<style scoped>

.box {
    padding: 10px 0;
    margin: 0;
    height: auto;
}

.pic img {
    width: 70px;
    height: 70px;
}

.title h3 {
    font-size: 1rem;
    font-weight: 600;
    margin: 0px;
    text-align: left;
}

.title p,span {
        color: #999999;
        margin: 0px;
        text-align: left;
        font-size: .8rem;
}

.title strong {
    display: block;
    color: #00BCD4;
    /* color: #ff5200e0; */
    margin: 0px;
        text-align: left;
}

.price div:first-child {
    color: #ffffff;
    background-color: #00BCD4;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.price div:last-child {
    color: #00BCD4;
    background-color: #ffffff;
    border: 1px solid #00BCD4;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
</style>
