
// stores/counter.js
import { defineStore } from 'pinia'

export const Store = defineStore('store', {
    state: () => {
      return {
          isMenu: true
        }
    },
    actions: {
      showMenu () {
        this.isMenu = true
      },
      hideMenu () {
        this.isMenu = false
      }
    }
})
