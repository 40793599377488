<template>
  <div class="wrap">
        <div class="jmodal">
            <div class="jmodal-title">
                <h3><slot name="title">标 题</slot></h3>
                <a href="#" @click="close"><i class="fa-solid fa-xmark"></i></a>
            </div>
            <div class="jmodal-content p-2">
                <slot name="content">我就是默认值</slot>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "jmodal",
    setup (props,{ emit }) {
        function close () {
            emit('close')
            console.log("请求关闭窗口")
        }

        return {
            close
        }
    }
}
</script>

<style scoped>
.wrap {
    z-index: 9999;
    position: fixed;
    top: 0;
    background-color: rgba(0, 0, 0, .3);
    width: 100%;
    height: 100%;
}

.jmodal {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 80%;
    max-height: 80%;
    background-color: rgb(255, 251, 251);
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 10%;
    overflow:auto
}

.jmodal-title * {
    color: #727171;
}
.jmodal-title {
    display: flex;
    position: sticky;
    top:0;
    z-index: 10;
    width: 100%;
    margin: 0 auto;
    flex-direction: row;
    align-items: center;
    background-color: #B2DFDB;
    border-bottom: 0.5px solid #5bb5ad;
    padding: 3px 10px 3px ;
    border-radius: 5px 5px 0 0;
}

.jmodal-title h3 {
    flex: 1;
    margin: 0px;
    font-size: 1rem;
}

.jmodal-title a {
    flex: 1;
    text-align: right;
    color: #333333;
    cursor: pointer;
}


</style>