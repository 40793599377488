<template>
  <div class="ere">
    <router-view></router-view>
  </div>
  <navbar v-if="menu.isMenu"></navbar>
</template>

<script>
import { clearUrl } from '@/lib/unitls'
import navbar from '@/components/navbar'
import { Store } from '@/bus'


export default {
  name: 'App',
  components: {
    navbar
  },
  setup() {
    const menu = Store()
    //去弹窗Url
    clearUrl()
    return {
      menu
    }
  }
}
</script>

<style>
/* 搜索栏定位 */
.e-top {
    position: sticky;
    top: 0;
    z-index: 9999;
}
</style>
