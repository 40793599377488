<template>
    <div class="row py-1 my-1 mx-2">
            <input type="search" class="form-control" placeholder="请输入关键字" />
            <!-- <div class="search">
                <i class="fa-solid fa-magnifying-glass"></i>
                搜索
            </div> -->
    </div>

</template>

<script>
export default {
    name: "search"
}
</script>

<style scoped>
    .search{
        margin:0 auto;
        flex:auto;
        background:#fff;
        border-radius:0 50px 50px 0;
        height: calc(1.5em + 0.75rem + 2px);
        line-height: 2.2rem;
        width: 15px;
        border: 1px solid #ced4da;
        border-left:0;
    }
    .row input {
        flex:auto;
        width: auto;
        border-radius:50px;
        border-right:0;
    }
</style>