<template>
<div id="home">
  <div id="carouselExampleFade" class="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="https://picsum.photos/750/360" class="d-block w-100">
      </div>
      <div class="carousel-item">
        <img src="https://picsum.photos/750/360" class="d-block w-100">
      </div>
      <div class="carousel-item">
        <img src="https://picsum.photos/750/360" class="d-block w-100">
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
  <div class="top p-3 text-center fw-bold" v-if="topShow">
    专业靠谱的人力资源公司
    <p class="my-2 fs-8">专业团队为您导航，让您工作无忧</p>
  </div>
  <!-- <div class="bg-white w-100 sticky-top"> -->
    <div class="bg-white w-100">
    <div id="nav" class="d-flex flex-wrap py-1">
        <div class="navlist w-25 text-center py-2">
          <a @click="screen('timeJob','小时工')">
            <p class="m-0"><i class="fa-regular fa-clock"></i></p>
            <p class="m-0 fs-9">小时工</p>
          </a>
        </div>
        <div class="navlist w-25 text-center py-2">
          <a @click="screen('dailySettlement','日结工')">
          <p class="m-0"><i class="fa-regular fa-calendar-check"></i></p>
          <p class="m-0 fs-9">日结工</p>
          </a>
        </div>
        <div class="navlist w-25 text-center py-2">
          <a @click="screen('dayShift','长白班')">
          <p class="m-0"><i class="fa-regular fa-sun"></i></p>
          <p class="m-0 fs-9">长白班</p>
          </a>
        </div>
        <div class="navlist w-25 text-center py-2">
          <a @click="screen('refund','高返费')">
            <p class="m-0"><i class="fa-solid fa-hand-holding-dollar"></i></p>
            <p class="m-0 fs-9">高返费</p>
          </a>
        </div>
    </div>
    <hr class="my-0">
    
  </div>
    <div id="roll" class="p-2 px-3 d-flex bg-white">
      <div><i class="fw-bold i fs-1.3">招聘<br>头条</i></div>
      <div class="mx-2">97打工网</div>
    </div>
  <Joblist :data="data"></Joblist>
</div>
</template>

<script>

import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import Axios from 'axios'
import Joblist from '@/components/joblist.vue'
export default {
    name: "home",
    components: {
      Joblist
    },
    setup () {
      const topShow = ref(true)
      const router = useRouter()
      onMounted(() => {
        window.scrollTo(0, 0)
        document.title = "97打工网"
        getData()

        setTimeout(() => {
          topShow.value = false
        }, 8000);
      })
      //跳转筛选  type: timeJob 小时工; dailySettlement 日结工; dayShift 长白班；refund 高返费
      function screen(key,title) {
        router.push({path:'/searchjob/', query:{ type: key,title: title }})
      }

//招聘信息 显示为最新推荐职位
      const data = ref([])
      const a = 10
        for(var i = 0; i <a; i++) {
            data.value.push(
                {
                    id: 100 + i,
                    name: '97打工网' + i, // Number 岗位名称
                    tags:[1,3,4], // array 岗位优势
                    salarys: 2, //Number 工资范围
                    settlement: 0, // Number 结算方式
                    price: 20 + i, // number 小时工单价
                    logo:'https://picsum.photos/70/70' // Strying 企业logo
                }
            )
        }
        function getData(obj) {
            Axios.get('http://localhost:5000/jobs', obj).then((response) => {
                if(response.data.code == 0) {
                    data.value = response.data.data

                }
            })
        }
      
      return {
        screen,
        data,
        topShow
      }
    }
}
</script>

<style>
#home .navlist i{
  font-size:1.2em;
  color: #009688 !important;
}
#home p{
  color: rgb(122, 122, 122);
}

#home #roll .i {
  line-height: 1rem !important;
  color: #009688 !important;
}
</style>